import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Container, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import ListMatieres from "./ListMatieres"
import ListMatieresSemstreDeux from "./ListMatieresSemstreDeux";

const NoteExamenPrincipale = props => {
  const [section, setSection] = useState(1)

  const renderBlock = section => {
    if (section === 1) {
      return <ListMatieres />
    }else if(section === 2){
      return <ListMatieresSemstreDeux />
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Note DS2 | Cresus </title>
        </MetaTags>
        <Container fluid>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 1,
                  })}
                  onClick={() => {
                    setSection(1)
                  }}
                >
                  {props.t("Semstre 1")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 2,
                  })}
                  onClick={() => {
                    setSection(2)
                  }}
                >
                  {props.t("Semestre 2")}
                </NavLink>
              </NavItem>
            </Nav>
            <Card>
              <CardBody>{renderBlock(section)}</CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

NoteExamenPrincipale.propTypes = {
  t: PropTypes.any,
}
export default withRouter(withTranslation()(NoteExamenPrincipale))
