import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import APIS from "../../apis"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Row, Col, Spinner } from "reactstrap"
import { withRouter } from "react-router"
import FileDownload from "js-file-download"
//i18n
import { withTranslation } from "react-i18next"

const DataTableNote = props => {
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: props.t("Suivant"),
    prePageText: props.t("Précedent"),
    alwaysShowAllBtns: true,
    sizePerPage: 20,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )
  const Columns = () => [
    {
      dataField: "date",
      text: props.t("Date"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "type_examen",
      text: props.t("Type examen"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "matiere",
      text: props.t("Matiere"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "group",
      text: props.t("Groupe"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "semestre",
      text: props.t("Semestre"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Note",
      isDummyField: true,
      text: props.t("Note"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.active == 1 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "cornflowerblue", cursor: "pointer" }}
              className="fas fa-edit"
              onClick={() =>
                props.history.push({
                  pathname: "/AddNoteDs",
                  state: {
                    group_id: row.groupe_id,
                    matiere: row.matiere,
                    group: row.group,
                    matiere_id: row.matiere_id,
                    type_examen_id: row.type_examen_id,
                    date: row.date,
                    semestre_id: row.semestre_id,
                  },
                })
              }
            ></i>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#a7a3a3", cursor: "pointer" }}
              className="fas fa-edit"
            ></i>
          </div>
        ),
    },
    {
      dataField: "Export",
      isDummyField: true,
      text: props.t("Export"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.active == 1 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#319f57", cursor: "pointer", fontSize: "15px" }}
              className="fas fa-file-excel"
              onClick={() => upload(row)}
            ></i>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#A7A3A3", cursor: "pointer", fontSize: "15px" }}
              className="fas fa-file-excel"
            ></i>
          </div>
        ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const res = await APIS.post("examen/passer/list", {
      enseignant_id: enseignant_id,
      semestre_id: 2,
    }).then(res => {
      setOrders(res.data.Eamen)
    })
    setLoading(true)
  }, [])

  const upload = async row => {
    const res = await APIS.post(
      "scolarite/examen/passer/export",
      {
        group_id: row.groupe_id,
        matiere_id: row.matiere_id,
        type_examen_id: row.type_examen_id,
        semestre_id: 1,
      },
      { responseType: "blob" }
    ).then(res => {
      FileDownload(res.data, "NotesDS(" + row.group + ").xlsx")
    })
  }


  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
      {/* <> */}
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableNote))
DataTableNote.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.any,
  t: PropTypes.any,
}
