import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router"
import API from "../../apis"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Spinner,
  Table,
} from "reactstrap"

const Seances = props => {
  if (localStorage.getItem("userAuthEnseignant")) {
    var userAuthEnseignant = JSON.parse(
      localStorage.getItem("userAuthEnseignant")
    )
    var enseignant_id = userAuthEnseignant.user.enseignant_id
  }
  const state_element = props.location.state
  const [etudiantList, setEtudiantList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    const resE = await API.post(
      "seance/absance/get_absance_etudiant_bymatiere_type",
      {
        group_id: state_element.group_id,
        matiere_id: state_element.matiere_id,
        type: state_element.type,
        enseignant_id: enseignant_id,
      }
    ).then(resE => {
      setEtudiantList(resE.data.SeanceDate)
      setLoading(true)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <div>
                  <div className="d-flex justify-content-center">
                    <h5 className="mb-3 text-truncate">Groupe :</h5>
                    <h5
                      style={{
                        color: "#556ee6",
                        paddingLeft: "10px",
                      }}
                      className="mb-3 text-truncate"
                    >
                      {state_element.group}
                    </h5>
                  </div>
                  <div className="d-flex justify-content-center">
                    <h5 className="mb-3 text-truncate">Matière :</h5>
                    <h5
                      style={{
                        color: "#556ee6",
                        paddingLeft: "10px",
                      }}
                      className="mb-3 text-truncate"
                    >
                      <b> {state_element.matiere}</b>
                    </h5>
                  </div>
                  <Table className="mt-3" striped bordered hover>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }}>Etudiant</th>
                        <th style={{ textAlign: "center" }}>
                          {"Nombre d'absence"}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {etudiantList.map((el, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            {el.etudiant}
                          </td>
                          <td style={{ textAlign: "center" }}> {el.nbr} </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Col lg="6">
                      <div className="text-center mt-4">
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/StatAbsence")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    Chargement en cours...
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Seances)
Seances.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}
