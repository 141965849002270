import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Container, Card, CardBody, Row, Spinner } from "reactstrap"
import { withRouter } from "react-router"
//i18n
import { withTranslation } from "react-i18next"
import API from "../../apis"

const NoteAndPresence = props => {
  if (localStorage.getItem("userAuthEnseignant")) {
    var userAuthEnseignant = JSON.parse(
      localStorage.getItem("userAuthEnseignant")
    )
    var enseignant_id = userAuthEnseignant.user.enseignant_id
  }
  const [loading, setLoading] = useState(false)
  const [quarts, setQuarts] = useState([])
  const [jours, setJours] = useState([])

  useEffect(async () => {
    const resE = await API.post("emploi/get_by_id", {
      enseignant_id: enseignant_id,
      semestre_id: 1,
    }).then(resE => {
      setQuarts(resE.data.emploi[0].quarts)
      setJours(resE.data.emploi[0].jours)
      setLoading(true)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Note")} | Cresus </title>
        </MetaTags>
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <div>
                    <div className="table-responsive mt-4">
                      <table className="table table-bordered text-center">
                        <thead className="table table-light">
                          <tr>
                            <th></th>
                            {quarts.map(el => (
                              <th
                                key={el.id_quart}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {el.label_quart}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {jours.map((elem, index) => (
                            <tr key={index}>
                              <td style={{ fontWeight: "bold" }}>
                                {elem.label_jour}
                              </td>
                              {elem.seances.map((element, indice) => (
                                <td
                                  key={indice}
                                  style={{ padding: "0px" }}
                                  className="td-emploi"
                                >
                                  {element.options.map((elle, ind) => (
                                    <div
                                      key={ind}
                                      style={{
                                        padding: "5px 5px 5px 5px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        props.history.push({
                                          pathname: "/AddNoteAndPresence",
                                          state: elle,
                                        })
                                      }
                                    >
                                      <p
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {elle.label_matiere}
                                      </p>
                                      <p
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {elle.label_group}
                                      </p>
                                      <p
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {elle.label_salle}
                                      </p>
                                      {element.type == 1 ? (
                                        <div className="div-icon">
                                          <span className="icon-cour">C</span>
                                          {element.quan == 1 ? (
                                            <div className="icon_quan"></div>
                                          ) : null}
                                        </div>
                                      ) : element.type == 2 ? (
                                        <div className="div-icon">
                                          <span className="icon-td">TD</span>
                                          {element.quan == 1 ? (
                                            <div className="icon_quan"></div>
                                          ) : null}
                                        </div>
                                      ) : element.type == 3 ? (
                                        <div className="div-icon">
                                          <span className="icon-tp">TP</span>
                                          {element.quan == 1 ? (
                                            <div className="icon_quan"></div>
                                          ) : null}
                                        </div>
                                      ) : (
                                        <div className="div-icon">
                                          <span className="icon-autre">A</span>
                                          {element.quan == 1 ? (
                                            <div className="icon_quan"></div>
                                          ) : null}
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    Chargement ...
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(NoteAndPresence))
NoteAndPresence.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}
