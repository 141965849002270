import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  CardTitle,
  Col,
  Form,
  Label,
  Row,
  Modal,
  ModalBody,
  Container,
  Card,
  CardBody,
  Spinner, Input,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import APIS from "../../apis"
import { withTranslation } from "react-i18next"
import Select from "react-select"

const EditFicheVoeux = props => {
  const [disbutReg, setDisbutReg] = useState(true)

  var userAuthEnseignant = JSON.parse(
      localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var departement_id =userAuthEnseignant.user.departement_id
  var specialite_id =userAuthEnseignant.user.specialite_id
  var cycle_id =userAuthEnseignant.user.cycle_id
  var annee_id =userAuthEnseignant.user.annee_id
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  const [jours, setJours] = useState([
    { value: 1, label: "Lundi" },
    { value: 2, label: "Mardi" },
    { value: 3, label: "Mercredi" },
    { value: 4, label: "Jeudi" },
    { value: 5, label: "Vendredi" },
    { value: 6, label: "Samedi" },
  ])
  const [selectJour, setSelectJour] = useState("")
  const [matiere, setMatiere] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [type, setType] = useState([])
  const [selectType, setSelectType] = useState("")
  const [enseignant, setEnseignant] = useState([])
  const [selectEnseignant, setSelectEnseignant] = useState("")
  const [nbrGroupe, setNbrGroupe] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await APIS.post("fiche/get_by_id_detaille", { id }).then(res => {
      setSelectJour(res.data.FicheVoeau.jour)
      setSelectSemestre(res.data.FicheVoeau.semestre)
      setSelectMatiere(res.data.FicheVoeau.matiere)
      setSelectEnseignant(res.data.FicheVoeau.enseignant)
      setSelectType(res.data.FicheVoeau.type_matiere)
      setNbrGroupe(res.data.FicheVoeau.nb_group)
      setLoading(true)
    })
  }, [])

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    if (selectSemestre != "") {
      if (selectJour.length > 0) {
        const res = await APIS.post("fiche/update_ligne", {
          id: id,
          semestre_id: selectSemestre.value,
          jour: selectJour,
          matiere_id: selectMatiere.value,
          enseignant_id: selectEnseignant.value,
          nb_group: nbrGroupe,
          type_matiere: selectType.value,
          annee_id:annee_id,
        })
        // props.history.push("/FicheVoeux")
        if (res.data.errNum === 405) {
          toast.error("le nombre de group demander n'est pas disponible", {
            containerId: "A",
          })
        } else  if (res.data.errNum === 403)  {
          toast.success("Vous avez un groupe déjà affecté contacter espace scolarité ", {
            containerId: "A",
          })
        }else{
          props.history.push("/FicheVoeux")
        }
      } else {
        toast.error("Veuillez choisir les jours", {
          containerId: "A",
        })
      }
    } else {
      toast.error("Veuillez choisir un semestre ", {
        containerId: "A",
      })
    }
  }
  const getMatiere = async event => {
    setSelectSemestre(event)
    // setSelectJour("")
    setSelectMatiere("")
    setSelectType("")
    // setSelectEnseignant("")
    setNbrGroupe("")
    const res = await APIS.post(
        "matiere/get_matiere_by_departement_by_enseignant",
        { enseignant_id: enseignant_id, semestre_id: event.value ,departement_id:departement_id,sepecialite_id:specialite_id,cycle_id:cycle_id}
    ).then(res => {
      setMatiere(res.data.Matiere)
    })
  }
  const getType = event => {
    setNbrGroupe("")
    setSelectType("")
    setSelectMatiere(event)
    const res = APIS.post("matiere/get_detail", {
      matiere_id: event.value,
    }).then(res => {
      setType(res.data.Matiere)
    })
  }
  const getNbGroup = event => {
    setSelectType(event)
    const res = APIS.post("matiere/get_nb_group", {
      type: event.value,
      matiere_id:selectMatiere.value,
      annee_id:annee_id
    }).then(res => {
      setNbrGroupe(res.data.NbGroup)
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <Form>
                    <CardTitle style={{ color: "#556ee6" }} className="h4">
                      Modification de fiche de voeau
                    </CardTitle>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Semestre")} :
                          </Label>
                          <Select
                              value={selectSemestre}
                              options={semestre}
                              isSearchable={true}
                              onChange={e => getMatiere(e)}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Choix des jours")} :
                          </Label>
                          <Select
                              value={selectJour}
                              isMulti={true}
                              options={jours}
                              isSearchable={true}
                              onChange={e => setSelectJour(e)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Matière")} :
                          </Label>
                          <Select
                              value={selectMatiere}
                              options={matiere}
                              isSearchable={true}
                              onChange={e => getType(e)}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Type")} :
                          </Label>
                          <Select
                              value={selectType}
                              options={type}
                              isSearchable={true}
                              onChange={e => getNbGroup(e)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Enseignant")} :
                          </Label>
                          <Select
                              value={selectEnseignant}
                              options={enseignant}
                              isSearchable={true}
                              onChange={e => setSelectEnseignant(e)}
                              isDisabled={true}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Nombre de groupes")} :
                          </Label>
                          <Input
                              lg="3"
                              className="form-control"
                              placeholder="Entrer nombre de groupes"
                              type="number"
                              value={nbrGroupe}
                              onChange={e => setNbrGroupe(e.target.value)}
                          />
                          <h6 style={{color:"red"}}>{"Vous êtes limité à un certain nombre et il n'est pas possible de le dépasser."}</h6>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/FicheVoeux")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={edit}
                        >
                          Confirmer
                        </button>
                      </div>
                    </Col>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    {"Chargement"} ...{" "}
                  </h4>
                </div>
              )}
              <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditFicheVoeux)
EditFicheVoeux.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
