import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import API from "../../apis"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import getDay from "date-fns/getDay"
import classnames from "classnames"
import AddNote from "./AddNote"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"

const AddNoteAndPresence = props => {
  const state_element = props.location.state
  const [date, setDate] = useState("")
  const [section, setSection] = useState()
  const [displayBlock, setDisplayBlock] = useState(false)
  const [listGroupe, setListGroupe] = useState([])

  const isWeekday = date => {
    const day = getDay(date)
    return day == state_element.id_jour
  }

  const display = async () => {
    if (date != "") {
      const res = await API.post("seance/absance/list_group", {
        emploi_id: state_element.id_emploi,
        matiere_id: state_element.id_matiere,
        jour_id: state_element.id_jour,
        quart_id: state_element.id_quart,
        type: state_element.type,
      }).then(res => {
        setDisplayBlock(true)
        setListGroupe(res.data.list)
      })
    } else {
      toast.error("⛔ Date obligatoire !!", {
        containerId: "A",
      })
    }
  }

  const renderBlock = section => {
    // convert date début
    if (date != "") {
      let DateNote = date
      let month = "" + (DateNote.getMonth() + 1)
      let day = "" + DateNote.getDate()
      let year = DateNote.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    }
    for (let i = 0; i < listGroupe.length; i++) {
      if (section === i) {
        return (
          <AddNote
            listGroup={listGroupe[i]}
            emploi_id={state_element.id_emploi}
            matiere_id={state_element.id_matiere}
            jour_id={state_element.id_jour}
            quart_id={state_element.id_quart}
            type={state_element.type}
            date={convertDate}
          />
        )
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-4" style={{ color: "#556EE6" }}>
                Ajout note & présence par seance
              </CardTitle>
              <Row>
                <Form>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Date
                          <span style={{ color: "red", paddingLeft: "5px" }}>
                            *
                          </span>
                        </Label>
                        <DatePicker
                          selected={date}
                          filterDate={isWeekday}
                          className="form-control ddate"
                          dateFormat="dd/MM/yyyy"
                          onChange={e => setDate(e)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Col lg="6">
                    <div className="text-center mt-4">
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={display}
                      >
                        Afficher
                      </button>
                    </div>
                  </Col>
                </div>
              </Row>
              {displayBlock ? (
                <Nav className="icon-tab nav-justified mt-3 mb-3">
                  {listGroupe.map((element, index) => (
                    <NavItem key={index}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: section === element.section,
                        })}
                        onClick={() => {
                          setSection(element.section)
                        }}
                      >
                        <span className="d-none d-sm-block">
                          {element.label_group}
                        </span>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              ) : null}
              {renderBlock(section)}
            </CardBody>
          </Card>
        </Container>
      </div>
      <ToastContainer
        transition={Flip}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AddNoteAndPresence
AddNoteAndPresence.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}
