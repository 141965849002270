import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router"
import API from "../../apis"
import { Button, Col, Spinner, Table } from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"
import FileDownload from "js-file-download"

const AddNote = props => {
  if (localStorage.getItem("userAuthEnseignant")) {
    var userAuthEnseignant = JSON.parse(
      localStorage.getItem("userAuthEnseignant")
    )
    var enseignant_id = userAuthEnseignant.user.enseignant_id
  }
  const [etudiantList, setEtudiantList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    const resE = await API.post("seance/absance/list_absance", {
      group_id: props.listGroup.id_group,
      matiere_id: props.matiere_id,
      quart_id: props.quart_id,
      enseignant_id: enseignant_id,
      type: props.type,
      date: props.date,
    }).then(resE => {
      setEtudiantList(resE.data.list)
      setLoading(true)
    })
  }, [props.listGroup.id_group])

  const changeNote = (event, index) => {
    let note = event
    if (parseFloat(note) >= 0 && parseFloat(note) <= 20) {
      setEtudiantList(
        etudiantList.map((el, id) =>
          id === index ? Object.assign(el, { note: note }) : el
        )
      )
    } else if (note == "") {
      setEtudiantList(
        etudiantList.map((el, id) =>
          id === index ? Object.assign(el, { note: "" }) : el
        )
      )
    } else {
      toast.error("⛔ Veuillez verifier la note saisie !!", {
        containerId: "A",
      })
    }
  }

  const changeAbsence = (event, index) => {
    let absence = event.target.checked
    if (absence == 1) {
      setEtudiantList(
        etudiantList.map((el, id) =>
          id === index ? Object.assign(el, { abs: 1, note: "" }) : el
        )
      )
    } else {
      setEtudiantList(
        etudiantList.map((el, id) =>
          id === index ? Object.assign(el, { abs: 0, note: 0 }) : el
        )
      )
    }
  }

  const save = async () => {
    setLoading(false)
    const res = await API.post("seance/absance/add", {
      array: etudiantList,
      group_id: props.listGroup.id_group,
      matiere_id: props.matiere_id,
      emploi_id: props.emploi_id,
      quart_id: props.quart_id,
      type: props.type,
      enseignant_id: enseignant_id,
      date: props.date,
    }).then(res => {
      const resE = API.post("seance/absance/list_absance", {
        group_id: props.listGroup.id_group,
        matiere_id: props.matiere_id,
        quart_id: props.quart_id,
        enseignant_id: enseignant_id,
        type: props.type,
        date: props.date,
      }).then(resE => {
        setEtudiantList(resE.data.list)
        setLoading(true)
      })
    })
  }

  const upload = async () => {
    const res = await API.post(
      "seance/absance/export",
      {
        group_id: props.listGroup.id_group,
        matiere_id: props.matiere_id,
        quart_id: props.quart_id,
        enseignant_id: enseignant_id,
        type: props.type,
        date: props.date,
      },
      { responseType: "blob" }
    ).then(res => {
      FileDownload(
        res.data,
        "Notes&Présence" +
          "(" +
          props.listGroup.label_group +
          ")" +
          "(" +
          props.date +
          ")" +
          ".xlsx"
      )
    })
  }

  return (
    <React.Fragment>
      {loading ? (
        <div>
          <Col>
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn  mb-2 me-2"
                onClick={upload}
              >
                <i className="fas fa-file-import me-1" />
                Export
              </Button>
            </div>
          </Col>
          <div className="mt-2">
            <h5 style={{ color: "red" }}>
              {" "}
              NB : Cette action est irréversible
            </h5>
            <p>
              {
                "Faites attention ! en cas d'erreur vous n'avez pas la permission de mettre à jour. vous serez obligé de contacter le service administratif"
              }
            </p>
          </div>
          <Table className="mt-3" striped bordered hover>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>CIN</th>
                <th style={{ textAlign: "center" }}>Etudiant</th>
                <th style={{ textAlign: "center" }}>Présence</th>
                <th style={{ textAlign: "center" }}>Note</th>
              </tr>
            </thead>
            <tbody>
              {etudiantList.map((el, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center" }}> {el.cin} </td>
                  <td style={{ textAlign: "center" }}> {el.name} </td>
                  <td className="square-switch" style={{ textAlign: "center" }}>
                    <input
                      type="checkbox"
                      id={"square-switch" + index}
                      switch="bool"
                      checked={el.abs}
                      disabled={el.active == 1 ? true : false}
                      onChange={e => changeAbsence(e, index)}
                    />
                    <label
                      htmlFor={"square-switch" + index}
                      data-on-label="P"
                      data-off-label="A"
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <input
                      type="number"
                      onChange={e => changeNote(e.target.value, index)}
                      value={el.note != null ? el.note : ""}
                      disabled={el.active == 1 ? true : false}
                      style={{ textAlign: "center" }}
                    ></input>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="mt-2">
            <h5 style={{ color: "red" }}>
              {" "}
              NB : Cette action est irréversible
            </h5>
            <p>
              {
                "Faites attention ! en cas d'erreur vous n'avez pas la permission de mettre à jour. vous serez obligé de contacter le service administratif"
              }
            </p>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Col lg="6">
              <div className="text-center mt-4">
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={save}
                >
                  Confirmer
                </button>
              </div>
            </Col>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            Chargement en cours...
          </h4>
        </div>
      )}
      <ToastContainer
        transition={Flip}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(AddNote)
AddNote.propTypes = {
  listGroup: PropTypes.object,
  emploi_id: PropTypes.object,
  matiere_id: PropTypes.object,
  jour_id: PropTypes.object,
  quart_id: PropTypes.object,
  type: PropTypes.object,
  date: PropTypes.object,
}
