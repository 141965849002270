import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Container, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import SurveillanceSem from "./SurveillanceSem"

const Surveillance = props => {
  const [section, setSection] = useState(0)
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1", section: 0 },
    { value: 2, label: "Semestre 2", section: 1 },
  ])

  const renderBlock = section => {
    for (let i = 0; i < semestre.length; i++) {
      if (section === i) {
        return (
          <SurveillanceSem
            semestre_value={semestre[i].value}
            semestre_label={semestre[i].label}
          />
        )
      }
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Compostage | Cresus </title>
        </MetaTags>
        <Container fluid>
          <div>
            <Nav tabs>
              {semestre.map((element, index) => (
                <NavItem key={index}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: section === element.section,
                    })}
                    onClick={() => {
                      setSection(element.section)
                    }}
                  >
                    <span className="d-none d-sm-block">{element.label}</span>
                  </NavLink>
                </NavItem>
              ))}{" "}
            </Nav>
            <Card>
              <CardBody>{renderBlock(section)}</CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

Surveillance.propTypes = {
  t: PropTypes.any,
}
export default withRouter(withTranslation()(Surveillance))
