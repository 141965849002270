import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import API from "../../apis"
import Select from "react-select"
import {
  Row,
  Col,
  CardTitle,
  Label,
  Form,
  Container,
  CardBody,
  Card,
  Modal,
  ModalBody,
  Button,
  Spinner,
} from "reactstrap"
import { withRouter } from "react-router"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import addDays from "date-fns/addDays"

const AddDemandeLivre = props => {
  if (localStorage.getItem("userAuthEnseignant")) {
    var userAuthEnseignant = JSON.parse(
      localStorage.getItem("userAuthEnseignant")
    )
    var enseignant_id = userAuthEnseignant.user.enseignant_id
  }
  const [livres, setLivres] = useState([])
  const [selectLivre, setSelectLivre] = useState("")
  const [themes, setThemes] = useState([])
  const [selectTheme, setSelectTheme] = useState("")
  const [auteurs, setAuteurs] = useState([])
  const [selectAuteur, setSelectAuteur] = useState("")
  const [editeurs, setEditeurs] = useState([])
  const [selectEditeur, setSelectEditeur] = useState("")
  const [motCle, setMotCle] = useState([])
  const [selectMotCle, setSelectMotCle] = useState("")
  const [modal, setModal] = useState(false)
  const [livreId, setLivreId] = useState("")
  const [dateDebut, setDateDebut] = useState(new Date())
  const [dateFin, setDateFin] = useState(new Date())
  const [exemplaire, setExemplaire] = useState("")
  const [loading, setLoading] = useState(false)
  // Data Table
  const [orders, setOrders] = useState([])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Next",
    prePageText: "Back",
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )

  const Columns = () => [
    {
      dataField: "titre",
      text: "Titre",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "BibliothequeTheme",
      text: "Thème",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "auteur_id",
      text: "Auteur",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "editeur_id",
      text: "Editeur",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Réservation",
      isDummyField: true,
      text: "Réservation",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={{ color: "deeppink", cursor: "pointer" }}
            onClick={() => toggle(row)}
            className="fas fa-ticket-alt"
          ></i>
        </div>
      ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const res = await API.get("livre/liste", {}).then(res => {
      setOrders(res.data.Livre)
      setLoading(true)
    })
    const resL = await API.get("livre/select").then(resL => {
      setLivres(resL.data.Livre)
    })
    const resT = await API.get("theme/select").then(resT => {
      setThemes(resT.data.BibliothequeTheme)
    })
    const resA = await API.get("auteur/select").then(resA => {
      setAuteurs(resA.data.auteur)
    })
    const resE = await API.get("editeur/select").then(resE => {
      setEditeurs(resE.data.editeur)
    })
    const resM = await API.get("mot/select").then(resM => {
      setMotCle(resM.data.LivreMotCle)
    })
  }, [])

  const search = async () => {
    if (selectLivre != "") {
      var livre_id = selectLivre.value
    } else {
      var livre_id = 0
    }
    if (selectTheme != "") {
      var theme_id = selectTheme.value
    } else {
      var theme_id = 0
    }
    if (selectAuteur != "") {
      var auteur_id = selectAuteur.value
    } else {
      var auteur_id = 0
    }
    if (selectEditeur != "") {
      var editeur_id = selectEditeur.value
    } else {
      var editeur_id = 0
    }
    if (selectMotCle != "") {
      var mot_cle_id = selectMotCle.value
    } else {
      var mot_cle_id = 0
    }
    const res = await API.post("livre/search", {
      livre_id: livre_id,
      bibliotheque_theme_id: theme_id,
      auteur_id: auteur_id,
      editeur_id: editeur_id,
      mot_id: mot_cle_id,
    }).then(res => {
      setOrders(res.data.Livre)
    })
  }

  const searchAll = async () => {
    const res = await API.get("livre/liste").then(res => {
      setOrders(res.data.Livre)
    })
  }

  const toggle = async row => {
    setModal(true)
    setLivreId(row.id)
    const res = await API.post("reservation/get_exemplaire", {
      id: row.id,
    }).then(res => {
      setExemplaire(res.data.Livre.nb_exemplaire)
    })
  }

  const toggleEnd = async row => {
    setModal(false)
  }

  const validate = async () => {
    // convert date début
    let date = dateDebut
    let month = "" + (date.getMonth() + 1)
    let day = "" + date.getDate()
    let year = date.getFullYear()
    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day
    let convertDateDebut = [year, month, day].join("-")
    // convert heure debut
    let hd = dateDebut
    let hoursHd = hd.getHours()
    let minutesHd = hd.getMinutes()
    minutesHd = minutesHd.toString()
    if (minutesHd.length < 2) {
      if (minutesHd == "0") {
        minutesHd = "00"
      } else {
        minutesHd.concat("", "0")
      }
    }
    let secondsHd = hd.getSeconds()
    secondsHd = secondsHd.toString()
    if (secondsHd.length < 2) {
      if (secondsHd == "0") {
        secondsHd = "00"
      } else {
        secondsHd.concat("", "0")
      }
    }
    let convertHd =
      convertDateDebut + " " + hoursHd + ":" + minutesHd + ":" + secondsHd
    // convert date fin
    let dateF = dateFin
    let monthF = "" + (dateF.getMonth() + 1)
    let dayF = "" + dateF.getDate()
    let yearF = dateF.getFullYear()
    if (monthF.length < 2) monthF = "0" + monthF
    if (dayF.length < 2) dayF = "0" + dayF
    let convertDateFin = [yearF, monthF, dayF].join("-")
    let hf = dateFin
    let hoursHf = hf.getHours()
    let minutesHf = hf.getMinutes()
    minutesHf = minutesHf.toString()
    if (minutesHf.length < 2) {
      if (minutesHf == "0") {
        minutesHf = "00"
      } else {
        minutesHf.concat("", "0")
      }
    }
    let secondsHf = hf.getSeconds()
    secondsHf = secondsHf.toString()
    if (secondsHf.length < 2) {
      if (secondsHf == "0") {
        secondsHf = "00"
      } else {
        secondsHf.concat("", "0")
      }
    }
    let convertHf =
      convertDateFin + " " + hoursHf + ":" + minutesHf + ":" + secondsHf
    //
    if (convertHd == "") {
      toast.error("⛔ Date début obligatoire", {
        containerId: "A",
      })
    } else if (convertHf != "") {
      const res = await API.post("reservation/add", {
        date_debut: convertHd,
        date_fin: convertHf,
        livre_id: livreId,
        enseignant_id: enseignant_id,
      })
      if (res.data.status == 200) {
        const resA = API.get("livre/liste", {}).then(resA => {
          setOrders(resA.data.Livre)
        })
        setModal(!modal)
      } else if (res.data.status == 500) {
        toast.error(
          `Cet livre n'est pas disponible pour le moment , Il sera dipsonible le ${res.data.Reservation}`,
          {
            containerId: "A",
          }
        )
      }
    } else {
      toast.error("⛔ Date fin obligatoire", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {loading ? (
          <Container fluid>
            <Card>
              <CardBody>
                <CardTitle style={{ color: "#556ee6" }} className="h4">
                  Critéres de Recherches
                </CardTitle>
                <Form className="mt-4">
                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-lastname-input2">Livres :</Label>
                        <Select
                          options={livres}
                          isClearable={true}
                          isSearchable={true}
                          onChange={e => setSelectLivre(e)}
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-lastname-input2">Théme :</Label>
                        <Select
                          options={themes}
                          isSearchable={true}
                          isClearable={true}
                          onChange={e => setSelectTheme(e)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-lastname-input2">Auteur :</Label>
                        <Select
                          options={auteurs}
                          isClearable={true}
                          isSearchable={true}
                          onChange={e => setSelectAuteur(e)}
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-lastname-input2">Editeur :</Label>
                        <Select
                          options={editeurs}
                          isClearable={true}
                          isSearchable={true}
                          onChange={e => setSelectEditeur(e)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-lastname-input2">Mot clé :</Label>
                        <Select
                          options={motCle}
                          isClearable={true}
                          isSearchable={true}
                          onChange={e => setSelectMotCle(e)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
                <div
                  className="mb-4"
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  {" "}
                  <Col lg="6">
                    <div className="text-center mt-4">
                      <button
                        type="button"
                        style={{
                          backgroundColor: "#761C19",
                          borderColor: "#761C19",
                        }}
                        className="btn btn-success mb-2 me-2"
                        onClick={search}
                      >
                        Rechercher
                      </button>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="text-center mt-4">
                      <button
                        type="button"
                        style={{
                          backgroundColor: "#761C19",
                          borderColor: "#761C19",
                        }}
                        className="btn btn-success mb-2 me-2"
                        onClick={searchAll}
                      >
                        Afficher tout
                      </button>
                    </div>
                  </Col>
                </div>
                <Row>
                  <Col xs="12">
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={Columns()}
                      search
                      bootstrap4
                    >
                      {toolkitProps => (
                        <div>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <BootstrapTable
                            wrapperClasses="table-responsive"
                            noDataIndication={() => <NoDataIndication />}
                            striped={false}
                            bordered={false}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"table-light"}
                            hover
                            pagination={paginationFactory(pageOptions)}
                            {...toolkitProps.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              Chargement en cours...
            </h4>
          </div>
        )}
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
      <Modal
        style={{
          maxWidth: "90%",
          width: "50%",
        }}
        isOpen={modal}
        toggle={toggleEnd}
        centered={true}
      >
        <div
          className="modal-header"
          style={{
            width: "100px !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "20px",
            color: "#556ee6",
          }}
          toggle={toggleEnd}
        >
          Réservation livre
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Date Début:</Label>
                  <DatePicker
                    selected={dateDebut}
                    className="form-control ddate"
                    dateFormat="dd/MM/yyyy h:mm aa"
                    minDate={new Date()}
                    onChange={e => setDateDebut(e)}
                    showTimeSelect
                    timeIntervals={15}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Date Fin :</Label>
                  <DatePicker
                    selected={dateFin}
                    className="form-control ddate"
                    dateFormat="dd/MM/yyyy h:mm aa"
                    minDate={dateDebut}
                    maxDate={
                      exemplaire == 1 ? dateDebut : addDays(dateDebut, 30)
                    }
                    onChange={e => setDateFin(e)}
                    showTimeSelect
                    timeIntervals={15}
                  />
                </div>
              </Col>
            </Row>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button className="btn btn-success" onClick={validate}>
                  <i
                    style={{ color: "white", paddingRight: "5px" }}
                    className="fas fa-check-circle"
                  ></i>
                  Valider
                </Button>
              </div>
              <div>
                <Button onClick={toggleEnd} className="btn btn-warning">
                  <i
                    style={{ color: "white", paddingRight: "5px" }}
                    className="fas fa-times-circle"
                  ></i>
                  Annuler
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(AddDemandeLivre)
AddDemandeLivre.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
}
