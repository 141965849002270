import usFlag from "../assets/images/flags/us.jpg"
import tunisie from "../assets/images/flags/tun.jpg"
import france from "../assets/images/flags/french.jpg"

var lng = localStorage.getItem("I18N_LANGUAGE")
var languages = ""
if (lng == "ar") {
  languages = {
    fr: {
      label: "الفرنسية",
      flag: france,
    },
    en: {
      label: "الإنجليزية",
      flag: usFlag,
    },
    ar: {
      label: "العربية",
      flag: tunisie,
    },
  }
} else if (lng == "en") {
  languages = {
    fr: {
      label: "French",
      flag: france,
    },
    en: {
      label: "English",
      flag: usFlag,
    },
    ar: {
      label: "Arabic ",
      flag: tunisie,
    },
  }
} else if (lng == "fr") {
  languages = {
    fr: {
      label: "Français ",
      flag: france,
    },
    en: {
      label: "Anglais",
      flag: usFlag,
    },
    ar: {
      label: "Arabe",
      flag: tunisie,
    },
  }
}

export default languages
