import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap"
import API from "../../apis"
import PropTypes from "prop-types"

const StatAbsence = props => {
  if (localStorage.getItem("userAuthEnseignant")) {
    var userAuthEnseignant = JSON.parse(
      localStorage.getItem("userAuthEnseignant")
    )
    var enseignant_id = userAuthEnseignant.user.enseignant_id
  }
  const [listGroupe, setListGroupe] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    const resE = await API.post("seance/absance/matiere/groupe", {
      enseignant_id: enseignant_id,
    }).then(resE => {
      setListGroupe(resE.data.SeanceDate)
      setLoading(true)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        {loading ? (
          <Container fluid>
            <Row>
              {listGroupe.length != 0 ? (
                listGroupe.map((element, key) => (
                  <Col xl="4" sm="4" key={"_col_" + key}>
                    <Card>
                      <CardBody
                        onClick={() =>
                          props.history.push({
                            pathname: "/Seances",
                            state: element,
                          })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="mt-4 text-center">
                          <div className="d-flex justify-content-center">
                            <h5 className="mb-3 text-truncate">Groupe :</h5>
                            <h5
                              style={{
                                color: "#556ee6",
                                paddingLeft: "10px",
                              }}
                              className="mb-3 text-truncate"
                            >
                              {element.group}
                            </h5>
                          </div>
                          <div className="d-flex justify-content-center">
                            <h5 className="mb-3 text-truncate">Matière :</h5>
                            <h5
                              style={{
                                color: "#556ee6",
                                paddingLeft: "10px",
                              }}
                              className="mb-3 text-truncate"
                            >
                              <b>{element.matiere}</b>
                            </h5>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              ) : (
                <Card>
                  <CardBody>
                    <h4 className="text-center mt-4">
                      Aucune seance pour le moment 😉
                    </h4>
                  </CardBody>
                </Card>
              )}
            </Row>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              Chargement ...
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default StatAbsence
StatAbsence.propTypes = {
  history: PropTypes.object,
}
