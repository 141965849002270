import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../apis"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Row, Col, Spinner, Button } from "reactstrap"
import { withRouter } from "react-router"

const ListEtudiant = props => {
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "ordre",
      text: "Ordre",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "cin",
      text: "CIN",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },

    {
      dataField: "etudiant",
      text: "Nom & Prénom",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "compostage",
      text: "Compostage",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "note",
      text: "Note",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const resE = await API.post(
      "examen/final/list_etudiant_enseignant_valider",
      {
        examen_id: props.element.id,
        salle: props.listSalle.salle,
      }
    ).then(res => {
      setList(res.data.ListEtudiant)
      setLoading(true)
    })
  }, [props.listSalle.salle])

  return (
    <React.Fragment>
      {loading ? (
        <Row className="mt-4">
          <Col xs="12">
            <ToolkitProvider
              keyField="id"
              data={list}
              columns={Columns()}
              search
              bootstrap4
            >
              {toolkitProps => (
                <div>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        <a
                          href={
                            "https://islgb.scolarite.backcresus-institut.ovh/api/impression_list_emargement/" +
                            props.element.id +
                            "/" +
                            props.listSalle.salle
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            type="button"
                            color="success"
                            className="btn  mb-2 me-2"
                          >
                            <i className="fas fa-file-export me-1" />
                            Export
                          </Button>
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    noDataIndication={() => <NoDataIndication />}
                    striped={false}
                    bordered={false}
                    classes={"table align-middle table-nowrap"}
                    headerWrapperClasses={"table-light"}
                    hover
                    {...toolkitProps.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </Col>
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            Chargement ...
          </h4>
        </div>
      )}
    </React.Fragment>
  )
}

export default withRouter(ListEtudiant)
ListEtudiant.propTypes = {
  list: PropTypes.array,
  history: PropTypes.object,
  location: PropTypes.object,
  listSalle: PropTypes.object,
  element: PropTypes.object,
  t: PropTypes.any,
}
