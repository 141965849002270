import React from "react"
import { Container, CardBody, Card, Spinner } from "reactstrap"
import { withRouter } from "react-router"

const PageAutorisation = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <div style={{ textAlign: "center", marginTop: "3%" }}>
                <p>Vous n’avez pas le droit d’accéder à cette rubrique</p>
                <p>
                  Pour toutes informations complémentaires, veuillez contacter l administrateur de site web.
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p>Contact info : </p>
                  <p style={{ marginLeft: "5px", color: "blue" }}>
                    gerant@cresus.pro /
                  </p>

                  <p style={{ marginLeft: "5px", color: "blue" }}>
                    contact@cresus.pro
                  </p>
                </div>

                <p>+216 93 55 00 18</p>
                <p>+216 40 18 50 00</p>

              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(PageAutorisation)
