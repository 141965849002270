import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import Select from "react-select"
//i18n
import { withTranslation } from "react-i18next"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import APIS from "../../apis"
import { Col, Form, Label, Row, Nav, NavItem, NavLink } from "reactstrap"
import getDay from "date-fns/getDay"
import classnames from "classnames"
import AddNote from "./AddNote"

const AddTestLibre = props => {
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  //
  const [date, setDate] = useState(new Date())
  const [type, setType] = useState([])
  const [selectType, setSelectType] = useState([])
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [matiere, setMatiere] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [typeExamen, setTypeExamen] = useState([])
  const [selectTypeExamen, setSelectTypeExamen] = useState("")
  const [displayBlock, setDisplayBlock] = useState(false)
  const [section, setSection] = useState()
  const [listGroupe, setListGroupe] = useState([])
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")

  useEffect(async () => {
    const resT = await APIS.get("type_examen/select").then(resT => {
      setTypeExamen(resT.data.Type_examen)
    })
  }, [])

  const isWeekday = date => {
    const day = getDay(date)
    return day !== 0
  }

  const getMatiere = async e => {
    setSelectType(e)
    setSelectMatiere("")
    setSelectGroupe("")
    const res = await APIS.post("emploi/get_matiere", {
      type: e.value,
      enseignant_id: enseignant_id,
      semestre_id: selectSemestre.value,
    }).then(res => {
      setMatiere(res.data.matieres)
    })
  }

  const getGroupe = async e => {
    setSelectMatiere(e)
    setSelectGroupe("")
    const resG = await APIS.post("examen/passer/list/group/enseignant", {
      matiere_id: e.value,
      type: selectType.value,
      enseignant_id: enseignant_id,
      semestre_id: selectSemestre.value,
    }).then(resG => {
      setGroupe(resG.data.Group)
    })
  }
  const getTypeMatiere = async e => {
    setSelectSemestre(e)
    const res = await APIS.post("emploi/get_type_matiere", {
      enseignant_id: enseignant_id,
      semestre_id: e.value,
    }).then(res => {
      setType(res.data.emploi)
    })
  }

  const display = async () => {
    const res = await APIS.post("examen/passer/list/groupTd", {
      type: selectType.value,
      group_id: selectGroupe.value,
      semestre_id: selectSemestre.value,
    }).then(res => {
      setDisplayBlock(true)
      setListGroupe(res.data.Group)
    })
  }

  const renderBlock = section => {
    // convert date début
    if (date != "") {
      let DateNote = date
      let month = "" + (DateNote.getMonth() + 1)
      let day = "" + DateNote.getDate()
      let year = DateNote.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    }
    for (let i = 0; i < listGroupe.length; i++) {
      if (section === i) {
        return (
          <AddNote
            listGroup={listGroupe[i]}
            matiere_id={selectMatiere.value}
            type={selectType.value}
            typeExamen={selectTypeExamen.value}
            semestre={selectSemestre.value}
            date={convertDate}
            setSection={props.setSection}
          />
        )
      }
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Semestre")}
                </Label>
                <Select
                  options={semestre}
                  isSearchable={true}
                  value={selectSemestre}
                  onChange={e => getTypeMatiere(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Type examen")}
                </Label>
                <Select
                  options={typeExamen}
                  isSearchable={true}
                  value={selectTypeExamen}
                  onChange={e => setSelectTypeExamen(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Date")}
                  <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                </Label>
                <DatePicker
                  selected={date}
                  filterDate={isWeekday}
                  className="form-control ddate"
                  dateFormat="dd/MM/yyyy"
                  onChange={e => setDate(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Type</Label>
                <Select
                  options={type}
                  isSearchable={true}
                  value={selectType}
                  onChange={e => getMatiere(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Matiere</Label>
                <Select
                  options={matiere}
                  isSearchable={true}
                  value={selectMatiere}
                  onChange={e => getGroupe(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Groupe</Label>
                <Select
                  options={groupe}
                  isSearchable={true}
                  value={selectGroupe}
                  onChange={e => setSelectGroupe(e)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Col lg="6">
            <div className="text-center mt-4">
              <button
                type="button"
                className="btn btn-primary "
                onClick={display}
              >
                Afficher
              </button>
            </div>
          </Col>
        </div>{" "}
        {displayBlock ? (
          <Nav className="icon-tab nav-justified mt-3 mb-3">
            {listGroupe.map((element, index) => (
              <NavItem key={index}>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === element.section,
                  })}
                  onClick={() => {
                    setSection(element.section)
                  }}
                >
                  <span className="d-none d-sm-block">{element.nom}</span>
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        ) : null}
        {renderBlock(section)}
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(AddTestLibre))
AddTestLibre.propTypes = {
  history: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
