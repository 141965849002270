import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ResetPassword from "../pages/Authentication/ResetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Emploi
import EmploiEnseignant from "../pages/Emploi/EmploiEnseignant"

//
import EmploiGroup from "../pages/EmploiGroups/EmploiGroup"

// Avis
import AvisAbsence from "../pages/AvisAbsence/AvisAbsence"
import EditAvisAbsence from "../pages/AvisAbsence/EditAvisAbsence"

import AvisRattrapage from "../pages/AvisRattrapage/AvisRattrapage"
import EditAvisRattrapage from "../pages/AvisRattrapage/EditAvisRattrapage"

import AvisTest from "pages/AvisTest/AvisTest"
import EditAvisTestLibre from "pages/AvisTest/EditAvisTestLibre"

// fiche absence
import DataTableFicheAbsence from "../pages/FicheAbsenceGroupe/DataTableFicheAbsence"

// note
import NoteAndPresence from "pages/NoteAndPresence/NoteAndPresence"
import AddNoteAndPresence from "pages/NoteAndPresence/AddNoteAndPresence"

import Note from "pages/Note/Note"
import AddNoteDs from "pages/Note/AddNoteDs"

import NoteDS2 from "pages/NoteDS2/NoteDS2"
import ListSalleDS2 from "../pages/NoteDS2/ListSalleDS2"
//
import StatAbsence from "pages/StatAbsence/StatAbsence"
import Seances from "pages/StatAbsence/Seances"

// Fiche Voeux
import FicheVoeux from "../pages/FicheVoeux/FicheVoeux"
import EditFicheVoeux from "../pages/FicheVoeux/EditFicheVoeux"
import FicheVoeuxEnseignant from "../pages/FicheVoeux/FicheVoeuxEnseignant"
import FicheEnseignant from "../pages/FicheVoeux/FicheEnseignant"
import AddFicheLibre from "../pages/FicheVoeux/AddFicheLibre"

// Enseignant
import Enseignant from "../pages/Enseignant/Enseignant"
import DetailEnseignants from "../pages/Enseignant/DetailEnseignants"
import EmploiEnseignants from "pages/Enseignant/EmploiEnseignants"

import NoteExamenPrincipale from "pages/NoteExamen/NoteExamenPrincipale"
import NoteExamenControle from "pages/NoteExamen/NoteExamenControle"
import ListSalle from "../pages/NoteExamen/ListSalle"

import ResultExamenPrincipale from "pages/ResultExamen/ResultExamenPrincipale"
import ResultatExamenControle from "pages/ResultExamen/ResultatExamenControle"
import ResultListSalle from "../pages/ResultExamen/ResultListSalle"

// Surveillance
import Surveillance from "../pages/Surveillance/Surveillance"

// Bibliothèque
import AddDemandeLivre from "../pages/Bibliotheque/AddDemandeLivre"
import ConsultationDemande from "../pages/Bibliotheque/ConsultationDemande"

import Tirage from "../pages/Tirage/Tirage"
import PageAutorisation from "../pages/PageAutorisation";

const authProtectedRoutes = [
  // PageAutorisation
  { path: "/PageAutorisation", component: PageAutorisation },
  { path: "/dashboard", component: Dashboard },

  // profile
  { path: "/profile", component: UserProfile },

  // Emploi
  { path: "/EmploiEnseignant", component: EmploiEnseignant },

  // Emploi Group
  { path: "/EmploiGroup", component: EmploiGroup },

  // Avis
  { path: "/AvisAbsence", component: AvisAbsence },
  { path: "/EditAvisAbsence", component: EditAvisAbsence },

  { path: "/AvisRattrapage", component: AvisRattrapage },
  { path: "/EditAvisRattrapage", component: EditAvisRattrapage },

  { path: "/AvisTest", component: AvisTest },
  { path: "/EditAvisTestLibre", component: EditAvisTestLibre },

  // fiche absence
  { path: "/FicheAbsence", component: DataTableFicheAbsence },

  // NoteAndPresence
  { path: "/NoteAndPresence", component: NoteAndPresence },
  { path: "/AddNoteAndPresence", component: AddNoteAndPresence },

  { path: "/Note", component: Note },
  { path: "/AddNoteDs", component: AddNoteDs },

  { path: "/NoteDS2", component: NoteDS2 },
  { path: "/ListSalleDS2", component: ListSalleDS2 },

  // NoteExamenPrincipale
  { path: "/NoteExamenPrincipale", component: NoteExamenPrincipale },
  { path: "/NoteExamenControle", component: NoteExamenControle },
  { path: "/ListSalle", component: ListSalle },

  { path: "/ResultExamenPrincipale", component: ResultExamenPrincipale },
  { path: "/ResultatExamenControle", component: ResultatExamenControle },
  { path: "/ResultListSalle", component: ResultListSalle },

  //
  { path: "/StatAbsence", component: StatAbsence },
  { path: "/Seances", component: Seances },

  // Fiche Voeux
  { path: "/FicheVoeux", component: FicheVoeux },
  { path: "/EditFicheVoeux", component: EditFicheVoeux },
  { path: "/FicheVoeuxEnseignant", component: FicheVoeuxEnseignant },
  { path: "/FicheEnseignant", component: FicheEnseignant },
  { path: "/AddFicheLibre", component: AddFicheLibre },

  // Enseignant
  { path: "/Enseignant", component: Enseignant },
  { path: "/DetailEnseignants", component: DetailEnseignants },
  { path: "/EmploiEnseignants", component: EmploiEnseignants },

  // Bibliotheque
  { path: "/AddDemandeLivre", component: AddDemandeLivre },
  { path: "/ConsultationDemande", component: ConsultationDemande },

  // Surveillance
  { path: "/Surveillance", component: Surveillance },

  { path: "/Tirage", component: Tirage },

  // Password

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/ResetPassword/:token", component: ResetPassword },
]

export { publicRoutes, authProtectedRoutes }
