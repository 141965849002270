import React, { useEffect, useState, useRef, Fragment } from "react"
import PropTypes from "prop-types"
import APIS from "../../apis"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//i18n
import { withTranslation } from "react-i18next"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Container,
  ModalFooter,
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import axios from "axios"
import { MetaTags } from "react-meta-tags"

const DataTableFicheAbsence = props => {
  var lng = localStorage.getItem("I18N_LANGUAGE")
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  //
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState("")
  // Data Table
  const [groupeEns, setGroupeEns] = useState([])
  //
  useEffect(async () => {
    const res = await APIS.post("group/get_by_ensiegnant_imp", {
      enseignant_id: enseignant_id,
      lang: lng,
    }).then(res => {
      setGroupeEns(res.data.Groups)
      setLoading(true)
    })
  }, [])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: groupeEns.length,
    page: 1,
    nextPageText: props.t("next"),
    prePageText: props.t("back"),
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: groupeEns.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )

  const { SearchBar } = Search

  const Columns = () => [
    {
      dataField: "specialite",
      text: props.t("Specialite"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "groupe",
      text: props.t("Groupe"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Fiche",
      isDummyField: true,
      text: props.t("Fiche"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <i
            onClick={""}
            style={{ cursor: "pointer" }}
            className="fas fa-print"
          ></i>
        </div>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Fiche d'absence")}</title>
        </MetaTags>
        <Container fluid>
          <div>
            {loading ? (
              <Row>
                <Col xs="12">
                  <ToolkitProvider
                    keyField="id"
                    data={groupeEns}
                    columns={Columns()}
                    search
                    bootstrap4
                  >
                    {toolkitProps => (
                      <div>
                        <Col sm="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          noDataIndication={() => <NoDataIndication />}
                          striped={false}
                          bordered={false}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"table-light"}
                          hover
                          pagination={paginationFactory(pageOptions)}
                          {...toolkitProps.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </Col>
              </Row>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <Spinner type="grow" className="ms-6" color="primary" />
                </div>
                <h4
                  style={{ textAlign: "center", marginTop: "2%" }}
                  className="ms-6"
                >
                  {" "}
                  {props.t("Chargement en cours...")}
                </h4>
              </div>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableFicheAbsence))
DataTableFicheAbsence.propTypes = {
  groupeEns: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
