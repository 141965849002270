import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import AddFicheVoeux from "./AddFicheVoeux"
import DataTableFicheVoeux from "./DataTableFicheVoeux"
import DataTableValidationSemestre1 from "./DataTableValidationSemestre1"
import APIS from "../../apis"
import DataTableValidationSemestre2 from "./DataTableValidationSemestre2"
import AddFicheLibre from "./AddFicheLibre";
import DataTableFicheVoeuxDetail from "./DataTableFicheVoeuxDetail";

const FicheVoeux = props => {
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var chefDepartement = userAuthEnseignant.user.chef
  const [section, setSection] = useState(1)

  const back = () => {
    setSection(1)
  }

  const renderBlock = section => {
    if (section === 1) {
      return <DataTableFicheVoeux />
    } else if (section === 2) {
      if (chefDepartement == 1) {
        return <DataTableValidationSemestre1 />
      }
    } else if (section === 3) {
      if (chefDepartement == 1) {
        return <DataTableValidationSemestre2 />
      }
    }
    else if (section === 4) {
      return <AddFicheLibre setSection={setSection} back={back} />
    } else if (section === 5) {
      if (chefDepartement == 1) {
        return <DataTableFicheVoeuxDetail />
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Fiche de voeux")} | Cresus </title>
        </MetaTags>
        <Container fluid>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 1,
                  })}
                  onClick={() => {
                    setSection(1)
                  }}
                >
                  {props.t("Fiche de voeux")}
                </NavLink>
              </NavItem>
              {chefDepartement === 1 ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: section === 2,
                    })}
                    onClick={() => {
                      setSection(2)
                    }}
                  >
                    {props.t("Validation fiche de voeux semestre 1")}
                  </NavLink>
                </NavItem>
              ) : null}
              {chefDepartement === 1 ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: section === 3,
                    })}
                    onClick={() => {
                      setSection(3)
                    }}
                  >
                    {props.t("Validation fiche de voeux semestre 2")}
                  </NavLink>
                </NavItem>
              ) : null}
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 4,
                  })}
                  onClick={() => {
                    setSection(4)
                  }}
                >
                  {props.t("Ajouter")} {props.t("fiche de voeux")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 5,
                  })}
                  onClick={() => {
                    setSection(5)
                  }}
                >
                  {props.t("Détail")} {props.t("fiche de voeux")}
                </NavLink>
              </NavItem>
            </Nav>
            <Card>
              <CardBody>{renderBlock(section)}</CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(FicheVoeux))
FicheVoeux.propTypes = {
  t: PropTypes.any,
}
