import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  CardTitle,
  Col,
  Form,
  Label,
  Row,
  Container,
  Card,
  CardBody,
  Modal,
  ModalBody,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../apis"
import { withTranslation } from "react-i18next"
import Select from "react-select"

const FicheVoeux = props => {
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  const [selectJour, setSelectJour] = useState("")
  const [specialite, setSpecialite] = useState([])
  const [matiere, setMatiere] = useState([])
  const [selectDepartement, setSelectDepartement] = useState("")
  const [selectSemestre, setSelectSemestre] = useState("")
  const [arrayFiche, setArrayFiche] = useState([])
  const [modalVal, setModalVal] = useState(false)
  const [idVal, setIdVal] = useState("")

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  }

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )

  useEffect(async () => {
    const resS = await API.get("specialite/get_by_niveau").then(resS => {
      setSpecialite(resS.data.Specialites)
    })
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const resG = await API.post("fiche/get_by_id", { id }).then(resG => {
      setSelectDepartement(resG.data.FicheVoeau.departement_id)
      setSelectJour(resG.data.FicheVoeau.jour_id)
      setArrayFiche(resG.data.FicheVoeau.ligne)
      setSelectSemestre(resG.data.FicheVoeau.semestre_id)
    })
  }, [])

  const addSpecialite = (event, index) => {
    let specialite = event
    const res = API.post("matiere/get_by_specialite", {
      specialite_id: event.value,
      semestre_id: selectSemestre.value,
    }).then(res => {
      setMatiere(res.data.Matiere)
    })
    setArrayFiche(
      arrayFiche.map((el, id) =>
        id === index ? Object.assign(el, { specialite: specialite }) : el
      )
    )
  }

  const addMatiere = (event, index) => {
    let matiere = event
    setArrayFiche(
      arrayFiche.map((el, id) =>
        id === index ? Object.assign(el, { matiere: matiere }) : el
      )
    )
  }

  const toggleVal = (id, indexOfligne) => {
    setModalVal(!modalVal)
    setIdVal(id)
  }

  const valide = async () => {
    const res = await API.post("fiche/valider", {
      id: idVal,
      etat: 1,
      annee_id: 6,
    }).then(res => {
      setModalVal(false)
      var url = window.location.href
      var array = url.split("=")
      var id = array[1]
      const resG = API.post("fiche/get_by_id", { id }).then(resG => {
        setArrayFiche(resG.data.FicheVoeau.ligne)
      })
    })
  }

  const save = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("fiche/edit", {
      id: id,
      departement_id: selectDepartement.value,
      semestre_id: selectSemestre.value,
      jour: selectJour,
      ligne: arrayFiche,
    }).then(res => {
      props.history.push("/FicheVoeux")
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                <Form>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          {props.t("Département")} :
                        </Label>
                        <Select value={selectDepartement} isSearchable={true} />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          {props.t("Semestre")} :
                        </Label>
                        <Select value={selectSemestre} isSearchable={true} />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          {props.t("Choix des jours")} :
                        </Label>
                        <Select
                          isMulti={true}
                          value={selectJour}
                          isSearchable={true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <CardTitle
                    style={{ color: "#556ee6" }}
                    className="h4 mb-2 mt-2"
                  >
                    Choix du spécialité et matiére :
                  </CardTitle>
                  <table className="table table-bordered mt-4">
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }} scope="col">
                          Spécialité
                        </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Matiere
                        </th>
                        <th style={{ textAlign: "center" }} scope="col"></th>
                      </tr>
                    </thead>
                    {arrayFiche.map((el, index) => (
                      <tbody key={index}>
                        <tr>
                          <td className="col-md-5">
                            <Select
                              options={specialite}
                              isSearchable={true}
                              value={el.specialite}
                              onChange={e => addSpecialite(e, index)}
                              formatGroupLabel={formatGroupLabel}
                            />
                          </td>
                          <td className="col-md-5">
                            <Select
                              options={matiere}
                              value={el.matiere}
                              isSearchable={true}
                              onChange={e => addMatiere(e, index)}
                            />{" "}
                          </td>
                          <td
                            style={{ textAlign: "center" }}
                            className="col-md-1"
                          >
                            {el.etat == 0 ? (
                              <i
                                style={{ color: "#143F6B", cursor: "pointer" }}
                                onClick={() => toggleVal(el.id, index)}
                                className="fas fa-cogs"
                              ></i>
                            ) : (
                              <i
                                style={{ color: "#2ca67a", cursor: "pointer" }}
                                className="fas fa-check"
                                title="Validé"
                              ></i>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </Form>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  <Col lg="6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      className="text-center mt-4"
                    >
                      <Button
                        type="button"
                        color="warning"
                        className="btn btn-warning  mb-2 me-2"
                        onClick={() => props.history.push("/FicheVoeux")}
                      >
                        Annuler
                      </Button>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                      className="text-center mt-4"
                    >
                      <button
                        type="submit"
                        className="btn btn-primary "
                        onClick={save}
                      >
                        CONFIRMER
                      </button>
                    </div>
                  </Col>
                </div>
              </CardBody>
            </Card>
          </Row>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
        <Modal isOpen={modalVal} toggle={toggleVal} centered={true}>
          <div
            className="modal-header"
            style={{
              width: "100% !important",
              justifyContent: "center !important",
              margin: "0 auto",
              fontSize: "17px",
              fontWeight: "bold",
            }}
            toggle={toggleVal}
          >
            {props.t("Validation Voeu Enseignant ")}
          </div>
          <ModalBody>
            <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
            >
              <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <Button className="btn btn-success" onClick={valide}>
                    <i
                      style={{ color: "white", paddingRight: "5px" }}
                      className="fas fa-check-circle"
                    ></i>
                    {props.t("Valider")}
                  </Button>
                </div>
                <div>
                  <Button onClick={toggleVal} className="btn btn-warning">
                    <i
                      style={{ color: "white", paddingRight: "5px" }}
                      className="fas fa-times-circle"
                    ></i>
                    {props.t("Annuler")}
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(FicheVoeux)
FicheVoeux.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
