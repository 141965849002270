import AvField from "availity-reactstrap-validation/lib/AvField"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import React, { useState } from "react"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { Grid } from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import cresusFond from "../../assets/images/cresuFond.jpg"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"
import API from "../../api"
import { useParams } from "react-router-dom"

const ResetPassword = props => {
  const [password, setPassword] = useState("")
  const [verifPassword, setVerifPassword] = useState("")
  const [loading, setLoading] = useState(true)

  const { token } = useParams()

  const save = async () => {
    setLoading(false)
    if (password == verifPassword) {
      const res = await API.post("reset_password", {
        password: password,
        token: token,
      })
      if (res.data.status === 200) {
        toast.success(
          " Mot de passe a été modifier ",
          {
            containerId: "A",
          },
          props.history.push("/login")
        )
      } else {
        setLoading(true)
        toast.error("⛔ Token expiré", {
          containerId: "A",
        })
      }
    }
  }

  return (
    <div>
      {loading ? (
        <div
          style={{
            backgroundImage: `url(${cresusFond})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <div style={{ paddingTop: "7%" }}>
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <div>
                      <Row>
                        <Col className="justify-content-center pt-4">
                          <h1
                            style={{
                              color: "#556ee6",
                              textAlign: "center",
                            }}
                          >
                            Espace enseignant
                          </h1>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-4">
                      <div className="p-2">
                        <AvForm className="form-horizontal">
                          <div className="mb-3">
                            <Label>Password</Label>
                            <AvField
                              name="password"
                              type="password"
                              placeholder="Password"
                              onChange={e => setPassword(e.target.value)}
                              errorMessage="Min 8 caractère"
                              validate={{
                                required: { value: true },
                                min: { value: 8 },
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <Label>Verifier password</Label>
                            <AvField
                              name="password1"
                              type="password"
                              placeholder={"Réécrire password"}
                              onChange={e => setVerifPassword(e.target.value)}
                              errorMessage="Verifier le password"
                              validate={{
                                required: { value: true },
                                min: { value: 8 },
                                match: { value: "password" },
                              }}
                            />
                          </div>
                          <div className="mt-3 d-grid">
                            <button
                              onClick={save}
                              className="btn btn-primary btn-block"
                              type="submit"
                            >
                              Confirmer
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                    <div className="mt-1 text-center">
                      <p>
                        © {new Date().getFullYear()} Cresus. Crafted with{" "}
                        <i className="mdi mdi-heart text-danger" /> by{" "}
                        <a href="https://cresus.pro/">cresus.pro</a>
                      </p>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
            <ToastContainer
              transition={Flip}
              enableMultiContainer
              containerId={"A"}
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2500}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-50px",
            marginLeft: "-50px",
          }}
        >
          <Grid heigth="100" width="100" color="#86B5E9" ariaLabel="loading" />
        </div>
      )}
    </div>
  )
}

export default withRouter(ResetPassword)

ResetPassword.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
