import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../apis"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { withRouter } from "react-router"
import { ToastContainer, toast, Slide } from "react-toastify"
import warning from "../../assets/images/téléchargement.png"
import $ from "jquery"

const ListEtudiant = props => {
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [modal, setModal] = useState(false)
  const [modalAfficher, setModalAfficher] = useState(false);
  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "ordre",
      text: "Ordre",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "compostage",
      text: "Compostage",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Note",
      isDummyField: true,
      text: "Note",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => (
        <input
          className="input_test"
          type="text"
          id={row.etudiant_id}
          onChange={e => changeNote(e, row)}
          defaultValue={row.note}
          disabled={row.valider == 1 || row.note == "0" ? true : false}
          style={{ textAlign: "center" }}
        ></input>
      ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    setModalAfficher(false)

    const resE = await API.post("examen/final/list_etudiant_enseignant", {
      examen_id: props.element.id,
      salle: props.listSalle.salle,
    }).then(res => {
      setList(res.data.ListEtudiant)
      setLoading(true)
    })
  }, [props.listSalle.salle])

  const changeNote = (event, element) => {
    if (/^[0-9,.]/.test(event.target.value)) {
      var note = event.target.value
    } else {
      toast.error("⛔ Note doit comporte seulement des chiffres !", {
        containerId: "A",
      })
    }
    if (parseFloat(note) >= 0 && parseFloat(note) <= 20) {
      setList(
        list.map((el, id) =>
          el.etudiant_id === element.etudiant_id
            ? Object.assign(el, { note: note })
            : el
        )
      )
      $("#" + element.etudiant_id).css("border-color", "none")
    } else if (note == "") {
      setList(
        list.map((el, id) =>
          el.etudiant_id === element.etudiant_id
            ? Object.assign(el, { note: "" })
            : el
        )
      )
    } else {
      setModal(true)
      var note1 = note.slice(0, -1)
      $("#" + element.etudiant_id).val(note1)
    }
  }

  const save = async () => {
    const res = await API.post("examen/final/add_note", {
      list: list,
      examen_id: props.element.id,
    })
      .then(res => {
        props.history.push("/NoteExamenPrincipale")
      })
      .catch(() => {
        toast.error("⛔ Problème lors de l'insertion !", {
          containerId: "A",
        })
      })
  }

  const close = () => {
    setModal(false)
  }
  const toggleAfficher = id => {
    setModalAfficher(!modalAfficher)
  }
  const Afficher = async () => {
    // setModalDelete(!modalDelete)
    setLoading(false)
    const res = await API.post("examen/final/affiche_note", {
      examen_id: props.element.id,
      affiche:1,
    }).then(res => {
      props.history.push("/Note")
    })
  }
  const validate = async () => {
    const res = await API.post("examen/final/valider_note", {
      examen_id: props.element.id,
    })
      .then(res => {
        if (res.data.status === 200) {
          toast.success(" Les notes validées avec succès", {
            containerId: "A",
            onClose: () => props.history.push("/NoteExamenPrincipale"),
          })
        } else {
          toast.error("⛔ Il ya a des notes non inserer !", {
            containerId: "A",
          })
        }
      })
      .catch(() => {
        toast.error("⛔  Problème lors de l'insertion !", {
          containerId: "A",
        })
      })
  }

  return (
    <React.Fragment>
      <div className="mt-2">
        <h5 style={{ color: "red" }}> NB : Cette action est irréversible</h5>
        <p>
          {
            "Faites attention ! en cas d'erreur vous n'avez pas la permission de mettre à jour. vous serez obligé de contacter le service administratif"
          }
        </p>
      </div>
      {loading ? (
        <Row className="mt-4">
          <Col xs="12">
            <ToolkitProvider
              keyField="id"
              data={list}
              columns={Columns()}
              search
              bootstrap4
            >
              {toolkitProps => (
                <div>
                  <Col sm="4">
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar {...toolkitProps.searchProps} />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    noDataIndication={() => <NoDataIndication />}
                    striped={false}
                    bordered={false}
                    classes={"table align-middle table-nowrap"}
                    headerWrapperClasses={"table-light"}
                    hover
                    {...toolkitProps.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
            <div className="mt-2">
              <h5 style={{ color: "red" }}>
                {" "}
                NB : Cette action est irréversible
              </h5>
              <p>
                {
                  "Faites attention ! en cas d'erreur vous n'avez pas la permission de mettre à jour. vous serez obligé de contacter le service administratif"
                }
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
                >
                  <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={() => props.history.push("/NoteExamenPrincipale")}
                  >
                    Annuler
                  </Button>
                </div>
              </Col>
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={save}
                  >
                    Sauvgarder
                  </button>
                </div>
              </Col>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Col lg="6">
                <div className="text-center mt-4">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={validate}
                  >
                    Valider
                  </button>
                </div>
              </Col>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Col lg="6">
                <div className="text-center mt-4">
                  <button
                      type="button"
                      className="btn btn-secondary"
                      // onClick={toggleAfficher}
                  >
                    Afficher la note pour les étudiants sur leur espace
                  </button>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            Chargement ...
          </h4>
        </div>
      )}
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={1500}
      />
      <Modal isOpen={modal} centered={true}>
        <ModalHeader style={{ color: "red" }} toggle={close} tag="h4">
          Faite Attention
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-center">
            <img
              src={warning}
              alt=""
              style={{ width: 100, textAlign: "center" }}
            />
          </div>
          <p className="mt-4" style={{ textAlign: "center" }}>
            <strong> Veuillez verifier la note saisie </strong>
          </p>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalAfficher} toggle={toggleAfficher} centered={true}>
        <ModalHeader
            style={{ width: "100% !important", textAlign: "center !important" }}
            toggle={toggleAfficher}
            tag="h4"
        >
          Affichage des notes DS
        </ModalHeader>
        <ModalBody>
          <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>{ "Êtes-vous sûr de vouloir valider cette action ? Si vous la validez, les notes seront affichées automatiquement dans l'espace étudiant !"}</p>
            </div>
            <div
                className="hvr-push mt-4"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
            >
              <div>
                <Button
                    color="info"
                    className="btn-rounded "
                    onClick={Afficher}
                >
                  <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                    onClick={toggleAfficher}
                    color="danger"
                    className="btn-rounded "
                >
                  <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

    </React.Fragment>
  )
}

export default withRouter(ListEtudiant)
ListEtudiant.propTypes = {
  list: PropTypes.array,
  history: PropTypes.object,
  location: PropTypes.object,
  listSalle: PropTypes.object,
  element: PropTypes.object,
  t: PropTypes.any,
}
