import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  Container,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import API from "../../apis"
import classnames from "classnames"
import ListEtudiant from "./ListEtudiant"

const ListSalle = props => {
  const element = props.location.state.element
  const [section, setSection] = useState()
  const [listSalle, setListSalle] = useState([])

  useEffect(async () => {
    const res = await API.post("examen/final/list_salle", {
      examen_id: element.id,
    }).then(res => {
      setListSalle(res.data.list)
    })
  }, [])

  const renderBlock = section => {
    for (let i = 0; i < listSalle.length; i++) {
      if (section === i) {
        return (
          <ListEtudiant
            listSalle={listSalle[i]}
            element={element}
          />
        )
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Nav className="icon-tab nav-justified mt-3 mb-3">
                {listSalle.map((element, index) => (
                  <NavItem key={index}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: section === element.section,
                      })}
                      onClick={() => {
                        setSection(element.section)
                      }}
                    >
                      <span className="d-none d-sm-block">{element.salle}</span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              {renderBlock(section)}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ListSalle
ListSalle.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}
