import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import Select from "react-select"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Progress,
  Spinner,
  CardTitle,
} from "reactstrap"
import apis from "apis"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import AvField from "availity-reactstrap-validation/lib/AvField"

const EditAvisAbsence = props => {
  const lng = localStorage.getItem("I18N_LANGUAGE")
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var nameEns = userAuthEnseignant.user.name
  var ens = { label: nameEns, value: enseignant_id }
  //
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  const [dateDebut, setDateDebut] = useState(new Date())
  const [dateFin, setDateFin] = useState(new Date())
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState("")
  const [cause, setCause] = useState("")
  const [uploadPercent, setUploadPercent] = useState(0)
  //
  useEffect(async () => {
    // Get By Id
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    setId(id)
    const res = await apis.post("avis/get_by_id_absence", { id }).then(res => {
      setDateDebut(new Date(res.data.avis.date_debut))
      setDateFin(new Date(res.data.avis.date_fin))
      setCause(res.data.avis.cause)
      setFileName(res.data.avis.fichier)
      setLoading(true)
    })
  }, [])

  const addDateDebut = d => {
    setDateDebut(d)
  }

  const addDateFin = d => {
    setDateFin(d)
  }

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }

  const edit = async () => {
    var begin = dateDebut.getTime() / 1000
    var end = dateFin.getTime() / 1000
    if (end > begin) {
      if (cause == "") {
        toast.error("Cause obligatoire", {
          containerId: "A",
        })
      } else {
        // convert date début
        let date = dateDebut
        let month = "" + (date.getMonth() + 1)
        let day = "" + date.getDate()
        let year = date.getFullYear()
        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day
        let convertDateDebut = [year, month, day].join("-")
        // convert heure debut
        let hd = dateDebut
        let hoursHd = hd.getHours()
        let minutesHd = hd.getMinutes()
        minutesHd = minutesHd.toString()
        if (minutesHd.length < 2) {
          if (minutesHd == "0") {
            minutesHd = "00"
          } else {
            minutesHd.concat("", "0")
          }
        }
        let secondsHd = hd.getSeconds()
        secondsHd = secondsHd.toString()
        if (secondsHd.length < 2) {
          if (secondsHd == "0") {
            secondsHd = "00"
          } else {
            secondsHd.concat("", "0")
          }
        }
        let convertHd =
          convertDateDebut + " " + hoursHd + ":" + minutesHd + ":" + secondsHd
        // convert date fin
        let dateF = dateFin
        let monthF = "" + (dateF.getMonth() + 1)
        let dayF = "" + dateF.getDate()
        let yearF = dateF.getFullYear()
        if (monthF.length < 2) monthF = "0" + monthF
        if (dayF.length < 2) dayF = "0" + dayF
        let convertDateFin = [yearF, monthF, dayF].join("-")
        let hf = dateFin
        let hoursHf = hf.getHours()
        let minutesHf = hf.getMinutes()
        minutesHf = minutesHf.toString()
        if (minutesHf.length < 2) {
          if (minutesHf == "0") {
            minutesHf = "00"
          } else {
            minutesHf.concat("", "0")
          }
        }
        let secondsHf = hf.getSeconds()
        secondsHf = secondsHf.toString()
        if (secondsHf.length < 2) {
          if (secondsHf == "0") {
            secondsHf = "00"
          } else {
            secondsHf.concat("", "0")
          }
        }
        let convertHf =
          convertDateFin + " " + hoursHf + ":" + minutesHf + ":" + secondsHf
        // path file
        if (file != null) {
          var pathFile = file.name
        } else {
          var pathFile = fileName
        }
        //
        const res = await apis
          .post("avis/update_absence", {
            id: id,
            enseignant_id: ens,
            date_debut: convertHd,
            date_fin: convertHf,
            cause: cause,
            fichier: pathFile,
            annee: "2022",
            entite: "Avis d'absence",
          })
          .then(resD => {
            if (file != null) {
              const formData = new FormData()
              formData.append("document", file)
              formData.append("annee", "2022")
              formData.append("entite", "Avis d'absence")
              formData.append("id", nameEns)
              const options = {
                onUploadProgress: progressEvent => {
                  const { loaded, total } = progressEvent
                  let percent = Math.floor((loaded * 100) / total)
                  console.log(`${loaded}kb of ${total}kb | ${percent}%`)
                  if (percent < 100) {
                    setUploadPercent(percent)
                  }
                },
              }
              const resA = apis
                .post("file/import", formData, options)
                .then(resA => {
                  setUploadPercent(100)
                  props.history.push("/AvisAbsence")
                })
            } else {
              props.history.push("/AvisAbsence")
            }
          })
          .catch(() => {
            toast.error("Problème lors de l'insertion !", {
              containerId: "A",
            })
          })
      }
    } else {
      toast.error("Date fin doit étre supérieur a la date debut", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {loading ? (
          <Container fluid>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4" style={{ color: "#556EE6" }}>
                  {props.t("Modification avis d'absence")}
                </CardTitle>
                <Row>
                  <AvForm>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Date début")}
                          </Label>
                          <DatePicker
                            selected={dateDebut}
                            className="form-control ddate"
                            dateFormat="dd/MM/yyyy h:mm aa"
                            onChange={e => addDateDebut(e)}
                            showTimeSelect
                            timeIntervals={15}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Date fin")}
                          </Label>
                          <DatePicker
                            selected={dateFin}
                            className="form-control ddate"
                            dateFormat="dd/MM/yyyy h:mm aa"
                            onChange={e => addDateFin(e)}
                            showTimeSelect
                            timeIntervals={15}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {" "}
                            {props.t("Cause")}
                          </Label>
                          <AvField
                            name="cause"
                            errorMessage="* Cause obligatoire"
                            required
                            type="text"
                            className="form-control"
                            placeholder={props.t("Cause")}
                            value={cause}
                            onChange={e => setCause(e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Justification")}
                          </Label>
                          {uploadPercent <= 0 ? (
                            <Input
                              onChange={e => addFile(e)}
                              className="form-control"
                              type="file"
                              id="formFile"
                            />
                          ) : null}
                          {uploadPercent > 0 && uploadPercent < 100 ? (
                            <Progress
                              striped
                              className="progress-xl"
                              color="success"
                              value={uploadPercent}
                            >
                              {uploadPercent}%
                            </Progress>
                          ) : null}
                          {uploadPercent == 100 ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              Success
                              <i
                                style={{
                                  paddingLeft: "5px",
                                  color: "darkcyan",
                                  paddingTop: "3.5px",
                                }}
                                className="fas fa-check me-1"
                              />
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/AvisAbsence")}
                        >
                          {props.t("Annuler")}
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={edit}
                        >
                          {props.t("Confirmer")}
                        </button>
                      </div>
                    </Col>
                  </div>
                </Row>
              </CardBody>
            </Card>
            <ToastContainer
              transition={Slide}
              enableMultiContainer
              containerId={"A"}
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2500}
            />
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(EditAvisAbsence))
EditAvisAbsence.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
